import { format } from 'd3-format';
import React from 'react';

import WidgetTooltip from '../../../../components/widget/tooltip';
import { IPlace } from '../../../../modules/places/model';
import { replace, findColoursAlongGradient, formatKM2 } from '../../utils';
import { HumanFootprintMetric } from './model';

interface HumanFootprintConfig {
  metric: HumanFootprintMetric;
}

export const CONFIG = {
  parse: ({ metric }: HumanFootprintConfig, params, widgetConfig: any, place: IPlace) => {
    if (!metric?.year_data) {
      return {
        noData: true,
        chart: [],
        template: '',
      };
    }

    const { sentence, legendConfig } = widgetConfig;
    const { year_data, area_km2 } = metric;
    const years: number[] = Object.keys(year_data).map((year) => Number(year));
    const minYear = Math.min(...years);
    const maxYear = Math.max(...years);
    const minYearMean = year_data[minYear].mean;
    const maxYearMean = year_data[maxYear].mean;

    const maxHumanImpactValue = 50;

    const categoryNames = legendConfig.items
      .filter((legendItem) => legendItem.label)
      .map((legendItem) => legendItem.label);

    const categoryEdges = legendConfig.items.map((legendItem) => legendItem.categoryStart);
    const colourGradient = legendConfig.items.reduce((acc, legendItem) => {
      acc[legendItem.categoryStart] = legendItem.color;
      return acc;
    }, {});

    const colours = findColoursAlongGradient(categoryEdges, maxHumanImpactValue, colourGradient);

    const areas = {};
    categoryNames.toReversed().forEach((category, index) => {
      const colourIndex = categoryNames.length - 1 - index;
      areas[category] = {
        type: 'monotone',
        itemColor: false,
        stroke: colours[colourIndex],
        fill: colours[colourIndex],
        stackId: '1',
      };
    });

    const chart = [];
    for (const year of years) {
      // Sort metric histogram into area categories
      const categorySortedHistogram = {};
      categoryNames.forEach((category, i) => {
        const categoryStart = categoryEdges[i];
        const categoryEnd = categoryEdges[i + 1];
        categorySortedHistogram[category] = 0;
        for (const binEdge of Object.keys(year_data[year].histogram)) {
          const [binStart, binEnd] = binEdge.split('_').map((b) => parseFloat(b));
          if (categoryStart <= binStart && binEnd <= categoryEnd) {
            categorySortedHistogram[category] +=
              (year_data[year].histogram[binEdge] * 100) / area_km2;
          }
        }
      });
      chart.push({
        year,
        mean: year_data[year].mean,
        noDataAreaKm2: year_data[year].nodata_area_km2,
        meanChange: year_data[year].mean - minYearMean,
        ...categorySortedHistogram,
      });
    }

    const calculateEcologicallyIntactAreaPerc = (year: number) => {
      const yearResults = chart.find((e) => e.year === year);
      return yearResults[categoryNames[0]] + yearResults[categoryNames[1]];
    };

    const maxYearEcologicallyIntactAreaPerc = calculateEcologicallyIntactAreaPerc(maxYear);
    const minYearEcologicallyIntactAreaPerc = calculateEcologicallyIntactAreaPerc(minYear);

    const ecologicallyIntactAreaChangePerc =
      maxYearEcologicallyIntactAreaPerc - minYearEcologicallyIntactAreaPerc;

    const maxYearTotalEcologicallyIntactArea = (maxYearEcologicallyIntactAreaPerc * area_km2) / 100;
    const minYearTotalEcologicallyIntactArea = (minYearEcologicallyIntactAreaPerc * area_km2) / 100;
    const ecologicallyIntactAreaChange =
      maxYearTotalEcologicallyIntactArea - minYearTotalEcologicallyIntactArea;

    return {
      chart,
      template: replace(
        sentence.default,
        {
          location: place.name,
          minYear,
          maxYear,
          changeType: maxYearMean - minYearMean >= 0 ? 'increased' : 'decreased',
          minYearMean: format('.2f')(minYearMean),
          maxYearMean: format('.2f')(maxYearMean),
          ecologicallyIntactChangeType:
            ecologicallyIntactAreaChange >= 0 ? 'increased' : 'decreased',
          ecologicallyIntactAreaChangePerc: format('.2f')(
            Math.abs(ecologicallyIntactAreaChangePerc)
          ),
          ecologicallyIntactAreaChange: formatKM2(Math.abs(ecologicallyIntactAreaChange)),
          ecologicallyIntactAreaPerc: format('.2f')(maxYearEcologicallyIntactAreaPerc),
          minYearEcologicallyIntactArea: formatKM2(minYearTotalEcologicallyIntactArea),
          maxYearEcologicallyIntactArea: formatKM2(maxYearTotalEcologicallyIntactArea),
        },
        {},
        {
          className: 'text-bold',
        }
      ),

      config: {
        type: 'area',
        height: 300,
        margin: { top: 10, right: 10, left: 30, bottom: 0 },
        yKeys: { areas: areas },
        legend: {
          width: 148,
          layout: 'vertical',
          align: 'right',
          verticalAlign: 'middle',
          iconType: 'square',
          iconSize: 12,
          wrapperStyle: {
            paddingLeft: '28px',
            fontSize: '0.7rem',
          },
          payload: categoryNames.map((category, index) => {
            return {
              dataKey: category,
              value: category,
              type: 'square',
              color: colours[index],
            };
          }),
        },
        yTitle: 'Area (%)',
        xAxis: {
          dataKey: 'year',
          type: 'number',
          axisLine: {
            stroke: '#CCC',
            strokeWidth: 1,
          },
          domain: [minYear, maxYear],
          ticks: years,
          tick: {
            dy: 4,
            fontSize: '11px',
            fontWeight: 500,
            fill: '#FFF',
          },
          tickLine: true,
        },
        yAxis: {
          type: 'number',
          axisLine: {
            stroke: '#CCC',
            strokeWidth: 1,
          },
          domain: [0, 100],
          ticks: [0, 25, 50, 75, 100],
        },
        cartesianGrid: {
          vertical: true,
          horizontal: false,
          strokeDasharray: '2 2',
        },
        tooltip: {
          cursor: false,
          content: ({ payload, active }) => {
            if (active && payload && payload.length > 0) {
              const { year, noDataAreaKm2 } = payload[0].payload;
              const settings: any[] = [
                {
                  label: `${year}`,
                },
                {
                  label: `Mean Human Footprint Score:<br><br>`,
                  key: 'mean',
                  format: (value) => format(',.2f')(value),
                },
              ];
              categoryNames.forEach((category, i) => {
                const categoryStart = categoryEdges[i];
                const categoryEnd = categoryEdges[i + 1];
                settings.push({
                  label: `Area in range ${format(',.0f')(categoryStart)}-${format(',.0f')(
                    categoryEnd
                  )}: `,
                  key: category,
                  format: (value) =>
                    `${formatKM2((value * area_km2) / 100)} km² (${format(',.2f')(value)}%)`,
                  color: colours[i],
                });
              });
              if (noDataAreaKm2) {
                settings.push({
                  label: 'No data area:',
                  key: 'noDataAreaKm2',
                  format: (value) =>
                    `${formatKM2(value)} km² (${format(',.2f')((value * 100) / area_km2)}%)`,
                });
              }
              return <WidgetTooltip hideZeros={false} payload={payload} settings={settings} />;
            }
            return null;
          },
        },
      },
    };
  },
};

export default CONFIG;
