/*
 * Licensed under the Apache License, Version 2.0 (the "License"); you may not use
 * this file except in compliance with the License. You may obtain a copy of the
 * License at
 *
 *  https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed
 * under the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
 * CONDITIONS OF ANY KIND, either express or implied. See the License for the
 * specific language governing permissions and limitations under the License.
 */

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { replace } from 'redux-first-router';

import { QUERIES } from '@marapp/earth-shared';

import { useAuth0 } from '../../../auth/auth0';
import Layers from '../../../components/layers';
import Places from '../../../components/places';
import WidgetsComponent from '../../../components/widgets/component';
import WidgetSkeleton from '../../../components/WidgetSkeleton';
import { useDashboards, useLocation } from '../../../fetchers';
import { setLastViewedPlace } from '../../../modules/global/actions';
import { EMainType } from '../../../modules/global/model';
import { toggleLayer } from '../../../modules/layers/actions';
import { ILayer } from '../../../modules/layers/model';
import { setLocationHighlight, setMapBounds } from '../../../modules/map/actions';
import { setPlacesSearch } from '../../../modules/places/actions';
import { setSidebarInfo } from '../../../modules/sidebar/actions';
import { EPanels } from '../../../modules/sidebar/model';
import { IUrlCoordinates } from '../../../utils/map';

interface IProps {
  selected: boolean;
  panel: EPanels;
  activeLayers: ILayer[];
  slug?: string;
  organization?: string;
  initialUrlCoordinates: IUrlCoordinates;
  setSidebarInfo?: (payload: any) => void;
  toggleLayer?: (payload: any) => void;
  setMapBounds?: (payload: any) => void;
  setPlacesSearch?: (payload: any) => void;
  setLastViewedPlace?: (payload: any) => void;
  setLocationHighlight?: (payload: any) => void;
}

function WithData(props: IProps) {
  const {
    slug,
    selected,
    panel,
    organization,
    activeLayers,
    initialUrlCoordinates,
    setPlacesSearch,
    setLocationHighlight,
    setMapBounds,
    setLastViewedPlace,
  } = props;
  const { groups } = useAuth0();
  const { data: placeData, error } = useLocation(slug, QUERIES.LOCATION.getOne(organization));
  const [metricsCalculating, setMetricsCalculating] = useState(true);
  const { data: placeDataWithoutGeoJSON, revalidate } = useLocation(
    slug,
    QUERIES.LOCATION.getOneWithoutGeoJSON(organization),
    {
      // Removed revalidation each 5 seconds. Instead introduced revalidation on focus and reconnect.
      revalidateOnFocus: true,
      revalidateOnReconnect: true,
    }
  );
  const { data: dashboardsData } = useDashboards(QUERIES.DASHBOARD.getWithWidgets());
  const hasData = !!(placeDataWithoutGeoJSON && dashboardsData);

  useEffect(() => {
    if (!placeData) {
      return;
    }
    setPlacesSearch({ search: placeData.name });

    if (!initialUrlCoordinates) {
      setMapBounds({ bbox: placeData.bbox2d });
    }
    setLocationHighlight({
      id: placeData.id,
      geojson: placeData.geojson,
    });

    setLastViewedPlace({
      id: placeData.id,
      name: placeData.name,
      slug: placeData.slug,
      organization: placeData.organization,
      mainType: EMainType.LOCATION,
      subType: placeData.type,
    });
  }, [placeData]);

  useEffect(() => {
    const metricsCalculatingInProgress =
      placeDataWithoutGeoJSON === undefined ||
      placeDataWithoutGeoJSON?.metrics?.some((metric) => metric?.meta?.isCalculating);
    setMetricsCalculating(metricsCalculatingInProgress);
  }, [placeDataWithoutGeoJSON]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!metricsCalculating) {
        clearInterval(intervalId); // Clear interval when metrics are done calculating
      } else {
        revalidate();
      }
    }, 5000);

    return () => clearInterval(intervalId); // Clear interval on component unmount
  }, [slug, metricsCalculating, revalidate]);

  useEffect(() => {
    if (error) {
      console.error(error);
      if ([403, 404].includes(error.status)) {
        replace('/404');
      }
    }
  }, [error]);

  if (panel === EPanels.LAYERS) {
    return (
      <Layers
        selected={selected}
        locationName={placeData?.name}
        locationOrganization={placeData?.organization}
      />
    );
  }

  if (panel === EPanels.PLACES) {
    return (
      <Places
        selected={selected}
        locationName={placeData?.name}
        locationOrganization={placeData?.organization}
      >
        {hasData ? (
          <div className="marapp-qa-indexsidebar">
            <div className="marapp-qa-indexcontent">
              <WidgetsComponent
                {...props}
                activeLayers={activeLayers}
                dashboards={dashboardsData}
                place={placeDataWithoutGeoJSON}
                groups={groups}
              />
            </div>
          </div>
        ) : (
          <WidgetSkeleton />
        )}
      </Places>
    );
  }
}

export default connect(
  (state: any) => ({
    activeLayers: state.layers.active,
    slug: state.router.payload.slug,
    organization: state.router.payload.organization,
    initialUrlCoordinates: state.map.initialUrlCoordinates,
  }),
  {
    setSidebarInfo,
    toggleLayer,
    setMapBounds,
    setPlacesSearch,
    setLastViewedPlace,
    setLocationHighlight,
  }
)(WithData);
