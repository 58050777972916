/*
  Licensed under the Apache License, Version 2.0 (the "License"); you may not use
  this file except in compliance with the License. You may obtain a copy of the
  License at

      https://www.apache.org/licenses/LICENSE-2.0

  Unless required by applicable law or agreed to in writing, software distributed
  under the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
  CONDITIONS OF ANY KIND, either express or implied. See the License for the
  specific language governing permissions and limitations under the License.
*/

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Fade from '@material-ui/core/Fade';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import useTheme from '@material-ui/core/styles/useTheme';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { pickBy, uniqueId } from 'lodash';
import IconChevronDown from 'mdi-material-ui/ChevronDown';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { cleanFilters, countFilters } from '../../utils/filters';

interface Filter {
  value: string;
  label: string;
  path: string[];
  count: number;
  key: string;
}

interface IProps {
  type?: string;
  open: boolean;
  onOpenToggle: (payload?) => void;
  onChange: (payload?) => void;
  filters: { [key: string]: string[] };
  availableFilters: { [key: string]: Filter[] };
  renderLive: boolean;
  defaultExpandedFilters?: { [key: string]: string[] };
}

interface ICurrentFilters {
  [key: string]: string[];
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey['600'],
    '&$expanded': {
      margin: 0,
    },
    '&:before': {
      display: 'none',
    },
  },
  expanded: {},
  summaryExpanded: {
    minHeight: '0 !important',
    '& >div:first-child': {
      marginTop: '12px !important',
      marginBottom: '12px !important',
    },
  },
  filterList: {
    marginBottom: '8px',
  },
}));

const FilterBy = (props: IProps) => {
  const {
    type,
    availableFilters,
    filters,
    onChange,
    renderLive,
    defaultExpandedFilters = {},
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const [currentAvailableFilters, setCurrentAvailableFilters] = useState<{
    [key: string]: Filter[];
  }>({});
  const [currentAvailableTags, setCurrentAvailableTags] = useState([]);
  const [autocompleteKey, setAutocompleteKey] = useState('');
  const initialFilters = useRef(availableFilters);
  const [currentFilters, setCurrentFilters] = useState(
    Object.keys(defaultExpandedFilters).length ? defaultExpandedFilters : filters
  );
  const [expandedFilters, setExpandedFilters] = useState(defaultExpandedFilters);

  useEffect(() => {
    if (renderLive) {
      onChange({ filters: currentFilters });
    }
  }, [currentFilters]);

  // Separate available filters and tags
  useEffect(() => {
    if (availableFilters && Object.keys(availableFilters).length !== 0) {
      setCurrentAvailableFilters(pickBy(availableFilters, (v, k) => k !== 'tags'));
      setCurrentAvailableTags(pickBy(availableFilters, (v, k) => k === 'tags').tags);
      if (Object.keys(initialFilters.current).length == 0) {
        initialFilters.current = availableFilters;
      }
    }
  }, [availableFilters]);

  const numberOfFilters = countFilters(currentFilters);

  const toggleFilter = (key: string, filter: Filter) => {
    const filterGroup = currentFilters[key] || [];
    const expandedFilterGroup = expandedFilters[key] || [];
    const exists = filterGroup.includes(filter.value);
    // if this is a category, and it has subcategories
    // then toggling it should also toggle on all subs
    const subCategories = currentAvailableFilters[key]
      ?.filter(
        (f) =>
          f.count > 0 && // only include subcategories with count > 0 to fix the visible filters count
          f.path.length > filter.path.length &&
          JSON.stringify(f.path.slice(0, filter.path.length)) === JSON.stringify(filter.path)
      )
      .map((f) => f.value);
    const newFilters = {
      [key]: exists
        ? filterGroup.filter((x) => ![filter.value, ...subCategories].includes(x))
        : [...filterGroup, filter.value, ...subCategories],
    };

    // If it's a new category, add it to the expanded filters
    if (!exists) {
      const newExpandedFilters = {
        [key]: [...expandedFilterGroup, filter.value, ...subCategories],
      };

      const newExpandedFiltersCleaned = cleanFilters({
        ...expandedFilters,
        ...newExpandedFilters,
      }) as ICurrentFilters;
      setExpandedFilters(newExpandedFiltersCleaned);
    }

    const newFiltersCleaned = cleanFilters({ ...currentFilters, ...newFilters }) as ICurrentFilters;
    setCurrentFilters(newFiltersCleaned);
  };

  const clearCheckedFilters = (ev) => {
    ev && ev.stopPropagation();
    setCurrentFilters({});
    // clear the autocomplete with a hard reset of the key
    // due to bug with multiple, can't just set inputValue
    setAutocompleteKey(uniqueId('autocomplete'));
  };

  const CustomPaper = (props) => {
    return <Paper elevation={8} {...props} />;
  };

  const ExpandableSubcategory = ({
    filter,
    checked,
    disabled,
    onChange,
    children,
    level,
    parentExpanded = true,
    parentLevel = 0,
    parentChecked = false, // Parent category checked state
  }) => {
    const [expanded, setExpanded] = useState(checked);

    useEffect(() => {
      setExpanded(checked);
    }, [checked]);

    const toggleExpand = () => {
      setExpanded((currentState) => !currentState);
    };

    const hasSubcategories = Array.isArray(children) && children.length > 0;
    const isChecked = (subcategory) =>
      !!(
        currentFilters[subcategory.key] &&
        currentFilters[subcategory.key].includes(subcategory.value)
      );

    const isExpanded = (subcategory) =>
      !!(
        expandedFilters[subcategory.key] &&
        expandedFilters[subcategory.key].includes(subcategory.value)
      );

    const isChildChecked =
      hasSubcategories && children.some((subcategory) => isChecked(subcategory));

    const isChildExpanded =
      hasSubcategories && children.some((subcategory) => isExpanded(subcategory));

    useEffect(() => {
      if (isChildChecked || isChildExpanded) {
        setExpanded(true);
      }
    }, [isChildChecked, isChildExpanded]);

    return (
      (level === 0 || level === parentLevel + 1) &&
      filter.count > 0 && (
        <>
          {
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <FormControlLabel
                style={{ paddingLeft: theme.spacing(3 * level), flexGrow: 1 }}
                label={
                  <span className="marapp-qa-filter-option">
                    {filter.label} <em>({filter.count})</em>
                  </span>
                }
                control={
                  <Checkbox
                    checked={checked}
                    disabled={disabled}
                    value={filter.value}
                    onChange={onChange}
                  />
                }
              />
              {hasSubcategories && (
                <IconButton size="small" onClick={toggleExpand}>
                  <IconChevronDown
                    className={
                      expanded
                        ? 'marapp-qa-expandable-chevron-open'
                        : 'marapp-qa-expandable-chevron-closed'
                    }
                    style={{ transform: expanded ? 'rotate(180deg)' : 'none' }}
                  />
                </IconButton>
              )}
            </div>
          }
          {hasSubcategories && parentExpanded && expanded && (
            <div>
              {children.map((subcategory) => (
                <ExpandableSubcategory
                  key={subcategory.value}
                  filter={subcategory}
                  checked={isChecked(subcategory)}
                  disabled={subcategory.count === 0 || parentChecked} // Disable subcategory if parent is checked
                  onChange={(_: any) => toggleFilter(subcategory.key, subcategory)}
                  level={subcategory.path.length - 1}
                  parentExpanded={expanded}
                  parentLevel={level}
                  parentChecked={isChecked(subcategory)}
                >
                  {currentAvailableFilters[subcategory.key]?.filter(
                    (f) =>
                      f.path.length > subcategory.path.length &&
                      JSON.stringify(f.path.slice(0, subcategory.path.length)) ===
                        JSON.stringify(subcategory.path)
                  )}
                </ExpandableSubcategory>
              ))}
            </div>
          )}
        </>
      )
    );
  };

  return (
    <Accordion
      style={{ marginTop: '-16px' }}
      classes={{
        root: classes.root,
        expanded: classes.expanded,
      }}
      // Apply filters when accordion is closed
      onChange={(e: React.SyntheticEvent, expanded: boolean) => {
        if (!expanded && !renderLive) {
          onChange({ filters: currentFilters });
        }
      }}
      defaultExpanded={Object.keys(defaultExpandedFilters).length > 0}
    >
      <AccordionSummary
        expandIcon={<IconChevronDown className="marapp-qa-filterbyarrow" />}
        classes={{
          expanded: classes.summaryExpanded,
        }}
      >
        <Typography>
          <Typography component="span" variant="button">
            {t('Filters')}
          </Typography>{' '}
          <Fade
            in={numberOfFilters > 0}
            timeout={{
              enter: theme.transitions.duration.enteringScreen,
              exit: 0, // quickly remove the button in order to hide "Clear(0)"
            }}
          >
            <Button
              className="marapp-qa-filterbyclear"
              onClick={clearCheckedFilters}
              size="small"
              variant="outlined"
              color="primary"
            >
              {t('Clear')} {`(${numberOfFilters})`}
            </Button>
          </Fade>
        </Typography>
      </AccordionSummary>
      {initialFilters.current.tags?.length || Object.keys(currentAvailableFilters).length > 0 ? (
        <AccordionDetails>
          <Grid container={true} spacing={1}>
            {initialFilters.current.tags?.length && (
              <Grid key={'tags'} item={true} xs={12} className={classes.filterList}>
                <Autocomplete
                  multiple={true}
                  autoSelect={true}
                  blurOnSelect={true}
                  id="marapp-qa-filter-tags"
                  key={autocompleteKey}
                  PaperComponent={CustomPaper}
                  size={'small'}
                  options={currentAvailableTags || []}
                  getOptionLabel={(option) => option.value}
                  getOptionSelected={(option, value) => option.value === value.value}
                  renderInput={(params) => (
                    <TextField {...params} variant="filled" label={t(`${type} tags`)} />
                  )}
                  onChange={(e: any, newValue) => {
                    // don't get change here, just full newValue
                    // so replace all of filters['tags'] with it
                    const newFilters = cleanFilters({
                      ...currentFilters,
                      tags: newValue.map((v) => v.value),
                    });
                    setCurrentFilters(newFilters as ICurrentFilters);
                  }}
                />
              </Grid>
            )}
            {Object.keys(currentAvailableFilters).map((key) => (
              <Grid item={true} key={`${type}-${key}`}>
                <Typography component="span" variant="subtitle2">
                  {t(`${type} ${key}`)}
                </Typography>
                {currentAvailableFilters[key].map((filter, i) => {
                  const checked = !!(
                    currentFilters[key] && currentFilters[key].includes(filter.value)
                  );
                  const disabled = filter.count === 0;
                  const isTopLevelCategory = filter.path.length === 1;
                  return (
                    filter.count > 0 &&
                    isTopLevelCategory && (
                      <Grid key={`${filter.key}-${filter.value}`} item={true} xs={12}>
                        <ExpandableSubcategory
                          filter={filter}
                          checked={checked}
                          disabled={disabled}
                          onChange={(e: any) => toggleFilter(key, filter)}
                          level={filter.path.length - 1}
                          parentChecked={checked}
                        >
                          {currentAvailableFilters[key]?.filter(
                            (f) =>
                              f.path.length > filter.path.length &&
                              JSON.stringify(f.path.slice(0, filter.path.length)) ===
                                JSON.stringify(filter.path)
                          )}
                        </ExpandableSubcategory>
                      </Grid>
                    )
                  );
                })}
              </Grid>
            ))}
          </Grid>
        </AccordionDetails>
      ) : (
        <LinearProgress />
      )}
    </Accordion>
  );
};

export default FilterBy;
