/*
  Licensed under the Apache License, Version 2.0 (the "License"); you may not use
  this file except in compliance with the License. You may obtain a copy of the
  License at

      https://www.apache.org/licenses/LICENSE-2.0

  Unless required by applicable law or agreed to in writing, software distributed
  under the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
  CONDITIONS OF ANY KIND, either express or implied. See the License for the
  specific language governing permissions and limitations under the License.
*/

export interface ILayerGroup {
  dataset: string;
  description: string;
  layers: ILayer[];
  legendType: 'yearpicker' | 'yeardatepicker';
  name: string;
  slug: string;
  visibility: boolean;
}

export interface ILayer {
  id: string;
  slug?: string;
  name?: string;
  description: string;
  type?: LayerType;
  organization?: string;
  provider?: LayerProvider;
  category: LayerCategory[];
  tags: string[];
  legendConfig: object;
  layerConfig: ILayerConfig;
  STACConfig?: ISTACConfig;
  decodeFunction?: any;
  interactionConfig: any;
  published: boolean;
  licenseRestriction: boolean;
  createdAt: Date;
  updatedAt: Date;
  version: number;
  references?: ILayer[];
  source?: any;
  paramsConfig?: any;
  sqlConfig?: any;
  decodeConfig?: any;
  timelineConfig?: any;
  render?: any;
}

export interface ILayerRaw extends ILayer {
  config: any;
}

interface ILayerConfig {
  paramsConfig: any;
  sqlConfig: any;
  decodeConfig: any;
  timelineConfig: any;
  type: LayerType;
  layers: ILayer[];
  body: any;
}

interface ISTACConfig {
  collection?: string;
  dataType?: string;
  asset?: string;
  startDate?: string;
  endDate?: string;
  STAC_API_URL?: string;
  TILER_URL?: string;
  STACLegendItems?: any[];
}

export enum LayerCategory {
  BOUNDARIES = 'Boundaries',
  ADMIN_AREAS = 'Boundaries/Administrative Areas',
  BIOGEOGRAPHIC_REGIONS = 'Boundaries/Biogeographical Regions',
  PROTECTED_CONSERVED_AREAS = 'Protected and Conserved Areas',
  LAND_COVER_AND_USE = 'Land Cover and Land Use',
  WATER = 'Water',
  WATER_FRESH = 'Water/Freshwater',
  WATER_OCEANS = 'Water/Oceans',
  CLIMATE_AND_CARBON = 'Climate and Carbon',
  CLIMATE_CHANGE_MITIGATION = 'Climate and Carbon/Climate Change Mitigation',
  CLIMATE_CHANGE_ADAPTATION = 'Climate and Carbon/Climate Change Adaptation',
  NATURE_BASED_SOLUTIONS = 'Nature Based Solutions',
  SUSTAINABLE_DEVELOPMENT = 'Sustainable Development',
  NATURAL_HAZARDS = 'Natural Hazards',
  BIODIVERSITY = 'Biodiversity',
  BIODIVERSITY_SPECIES = 'Biodiversity/Species',
  BIODIVERSITY_ECOSYSTEMS = 'Biodiversity/Ecosystem',
  BIODIVERSITY_GENES = 'Biodiversity/Genes',
  ECOSYSTEM_SERVICES = 'Ecosystem Services',
  HUMAN_IMPACT = 'Human Impact',
  AGRICULTURE = 'Agriculture',
  BUILT_ENVIRONMENT = 'Built Environment',
  RESTORATION = 'Restoration',
  SOCIETY = 'Society',
  SOCIOECONOMIC = 'Society/Socioeconomic',
  PUBLIC_HEALTH = 'Society/Public Health',
  SOCIAL_BEHAVIOR = 'Society/Social Behaviour',

  POLICY = 'Policy',
  KM_GBF = 'Policy/KM-GBF',

  KM_GBF_GOAL_A = 'Policy/KM-GBF/Goal A',
  KM_GBF_GOAL_A_HEADLINE = 'Policy/KM-GBF/Goal A/Headline Indicators',
  KM_GBF_GOAL_A_COMPONENT = 'Policy/KM-GBF/Goal A/Component Indicators',
  KM_GBF_GOAL_A_COMPLIMENTARY = 'Policy/KM-GBF/Goal A/Complimentary Indicators',

  KM_GBF_GOAL_B = 'Policy/KM-GBF/Goal B',
  KM_GBF_GOAL_B_HEADLINE = 'Policy/KM-GBF/Goal B/Headline Indicators',
  KM_GBF_GOAL_B_COMPONENT = 'Policy/KM-GBF/Goal B/Component Indicators',
  KM_GBF_GOAL_B_COMPLIMENTARY = 'Policy/KM-GBF/Goal B/Complimentary Indicators',

  KM_GBF_GOAL_C = 'Policy/KM-GBF/Goal C',
  KM_GBF_GOAL_C_HEADLINE = 'Policy/KM-GBF/Goal C/Headline Indicators',
  KM_GBF_GOAL_C_COMPONENT = 'Policy/KM-GBF/Goal C/Component Indicators',
  KM_GBF_GOAL_C_COMPLIMENTARY = 'Policy/KM-GBF/Goal C/Complimentary Indicators',

  KM_GBF_GOAL_D = 'Policy/KM-GBF/Goal D',
  KM_GBF_GOAL_D_HEADLINE = 'Policy/KM-GBF/Goal D/Headline Indicators',
  KM_GBF_GOAL_D_COMPONENT = 'Policy/KM-GBF/Goal D/Component Indicators',
  KM_GBF_GOAL_D_COMPLIMENTARY = 'Policy/KM-GBF/Goal D/Complimentary Indicators',

  KM_GBF_TARGET_1 = 'Policy/KM-GBF/Target 01',
  KM_GBF_TARGET_1_HEADLINE = 'Policy/KM-GBF/Target 01/Headline Indicators',
  KM_GBF_TARGET_1_COMPONENT = 'Policy/KM-GBF/Target 01/Component Indicators',
  KM_GBF_TARGET_1_COMPLIMENTARY = 'Policy/KM-GBF/Target 01/Complimentary Indicators',

  KM_GBF_TARGET_2 = 'Policy/KM-GBF/Target 02',
  KM_GBF_TARGET_2_HEADLINE = 'Policy/KM-GBF/Target 02/Headline Indicators',
  KM_GBF_TARGET_2_COMPONENT = 'Policy/KM-GBF/Target 02/Component Indicators',
  KM_GBF_TARGET_2_COMPLIMENTARY = 'Policy/KM-GBF/Target 02/Complimentary Indicators',

  KM_GBF_TARGET_3 = 'Policy/KM-GBF/Target 03',
  KM_GBF_TARGET_3_HEADLINE = 'Policy/KM-GBF/Target 03/Headline Indicators',
  KM_GBF_TARGET_3_COMPONENT = 'Policy/KM-GBF/Target 03/Component Indicators',
  KM_GBF_TARGET_3_COMPLIMENTARY = 'Policy/KM-GBF/Target 03/Complimentary Indicators',

  KM_GBF_TARGET_4 = 'Policy/KM-GBF/Target 04',
  KM_GBF_TARGET_4_HEADLINE = 'Policy/KM-GBF/Target 04/Headline Indicators',
  KM_GBF_TARGET_4_COMPONENT = 'Policy/KM-GBF/Target 04/Component Indicators',
  KM_GBF_TARGET_4_COMPLIMENTARY = 'Policy/KM-GBF/Target 04/Complimentary Indicators',

  KM_GBF_TARGET_5 = 'Policy/KM-GBF/Target 05',
  KM_GBF_TARGET_5_HEADLINE = 'Policy/KM-GBF/Target 05/Headline Indicators',
  KM_GBF_TARGET_5_COMPONENT = 'Policy/KM-GBF/Target 05/Component Indicators',
  KM_GBF_TARGET_5_COMPLIMENTARY = 'Policy/KM-GBF/Target 05/Complimentary Indicators',

  KM_GBF_TARGET_6 = 'Policy/KM-GBF/Target 06',
  KM_GBF_TARGET_6_HEADLINE = 'Policy/KM-GBF/Target 06/Headline Indicators',
  KM_GBF_TARGET_6_COMPONENT = 'Policy/KM-GBF/Target 06/Component Indicators',
  KM_GBF_TARGET_6_COMPLIMENTARY = 'Policy/KM-GBF/Target 06/Complimentary Indicators',

  KM_GBF_TARGET_7 = 'Policy/KM-GBF/Target 07',
  KM_GBF_TARGET_7_HEADLINE = 'Policy/KM-GBF/Target 07/Headline Indicators',
  KM_GBF_TARGET_7_COMPONENT = 'Policy/KM-GBF/Target 07/Component Indicators',
  KM_GBF_TARGET_7_COMPLIMENTARY = 'Policy/KM-GBF/Target 07/Complimentary Indicators',

  KM_GBF_TARGET_8 = 'Policy/KM-GBF/Target 08',
  KM_GBF_TARGET_8_HEADLINE = 'Policy/KM-GBF/Target 08/Headline Indicators',
  KM_GBF_TARGET_8_COMPONENT = 'Policy/KM-GBF/Target 08/Component Indicators',
  KM_GBF_TARGET_8_COMPLIMENTARY = 'Policy/KM-GBF/Target 08/Complimentary Indicators',

  KM_GBF_TARGET_9 = 'Policy/KM-GBF/Target 09',
  KM_GBF_TARGET_9_HEADLINE = 'Policy/KM-GBF/Target 09/Headline Indicators',
  KM_GBF_TARGET_9_COMPONENT = 'Policy/KM-GBF/Target 09/Component Indicators',
  KM_GBF_TARGET_9_COMPLIMENTARY = 'Policy/KM-GBF/Target 09/Complimentary Indicators',

  KM_GBF_TARGET_10 = 'Policy/KM-GBF/Target 10',
  KM_GBF_TARGET_10_HEADLINE = 'Policy/KM-GBF/Target 10/Headline Indicators',
  KM_GBF_TARGET_10_COMPONENT = 'Policy/KM-GBF/Target 10/Component Indicators',
  KM_GBF_TARGET_10_COMPLIMENTARY = 'Policy/KM-GBF/Target 10/Complimentary Indicators',

  KM_GBF_TARGET_11 = 'Policy/KM-GBF/Target 11',
  KM_GBF_TARGET_11_HEADLINE = 'Policy/KM-GBF/Target 11/Headline Indicators',
  KM_GBF_TARGET_11_COMPONENT = 'Policy/KM-GBF/Target 11/Component Indicators',
  KM_GBF_TARGET_11_COMPLIMENTARY = 'Policy/KM-GBF/Target 11/Complimentary Indicators',

  KM_GBF_TARGET_12 = 'Policy/KM-GBF/Target 12',
  KM_GBF_TARGET_12_HEADLINE = 'Policy/KM-GBF/Target 12/Headline Indicators',
  KM_GBF_TARGET_12_COMPONENT = 'Policy/KM-GBF/Target 12/Component Indicators',
  KM_GBF_TARGET_12_COMPLIMENTARY = 'Policy/KM-GBF/Target 12/Complimentary Indicators',

  KM_GBF_TARGET_13 = 'Policy/KM-GBF/Target 13',
  KM_GBF_TARGET_13_HEADLINE = 'Policy/KM-GBF/Target 13/Headline Indicators',
  KM_GBF_TARGET_13_COMPONENT = 'Policy/KM-GBF/Target 13/Component Indicators',
  KM_GBF_TARGET_13_COMPLIMENTARY = 'Policy/KM-GBF/Target 13/Complimentary Indicators',

  KM_GBF_TARGET_14 = 'Policy/KM-GBF/Target 14',
  KM_GBF_TARGET_14_HEADLINE = 'Policy/KM-GBF/Target 14/Headline Indicators',
  KM_GBF_TARGET_14_COMPONENT = 'Policy/KM-GBF/Target 14/Component Indicators',
  KM_GBF_TARGET_14_COMPLIMENTARY = 'Policy/KM-GBF/Target 14/Complimentary Indicators',

  KM_GBF_TARGET_15 = 'Policy/KM-GBF/Target 15',
  KM_GBF_TARGET_15_HEADLINE = 'Policy/KM-GBF/Target 15/Headline Indicators',
  KM_GBF_TARGET_15_COMPONENT = 'Policy/KM-GBF/Target 15/Component Indicators',
  KM_GBF_TARGET_15_COMPLIMENTARY = 'Policy/KM-GBF/Target 15/Complimentary Indicators',

  KM_GBF_TARGET_16 = 'Policy/KM-GBF/Target 16',
  KM_GBF_TARGET_16_HEADLINE = 'Policy/KM-GBF/Target 16/Headline Indicators',
  KM_GBF_TARGET_16_COMPONENT = 'Policy/KM-GBF/Target 16/Component Indicators',
  KM_GBF_TARGET_16_COMPLIMENTARY = 'Policy/KM-GBF/Target 16/Complimentary Indicators',

  KM_GBF_TARGET_17 = 'Policy/KM-GBF/Target 17',
  KM_GBF_TARGET_17_HEADLINE = 'Policy/KM-GBF/Target 17/Headline Indicators',
  KM_GBF_TARGET_17_COMPONENT = 'Policy/KM-GBF/Target 17/Component Indicators',
  KM_GBF_TARGET_17_COMPLIMENTARY = 'Policy/KM-GBF/Target 17/Complimentary Indicators',

  KM_GBF_TARGET_18 = 'Policy/KM-GBF/Target 18',
  KM_GBF_TARGET_18_HEADLINE = 'Policy/KM-GBF/Target 18/Headline Indicators',
  KM_GBF_TARGET_18_COMPONENT = 'Policy/KM-GBF/Target 18/Component Indicators',
  KM_GBF_TARGET_18_COMPLIMENTARY = 'Policy/KM-GBF/Target 18/Complimentary Indicators',

  KM_GBF_TARGET_19 = 'Policy/KM-GBF/Target 19',
  KM_GBF_TARGET_19_HEADLINE = 'Policy/KM-GBF/Target 19/Headline Indicators',
  KM_GBF_TARGET_19_COMPONENT = 'Policy/KM-GBF/Target 19/Component Indicators',
  KM_GBF_TARGET_19_COMPLIMENTARY = 'Policy/KM-GBF/Target 19/Complimentary Indicators',

  KM_GBF_TARGET_20 = 'Policy/KM-GBF/Target 20',
  KM_GBF_TARGET_20_HEADLINE = 'Policy/KM-GBF/Target 20/Headline Indicators',
  KM_GBF_TARGET_20_COMPONENT = 'Policy/KM-GBF/Target 20/Component Indicators',
  KM_GBF_TARGET_20_COMPLIMENTARY = 'Policy/KM-GBF/Target 20/Complimentary Indicators',

  KM_GBF_TARGET_21 = 'Policy/KM-GBF/Target 21',
  KM_GBF_TARGET_21_HEADLINE = 'Policy/KM-GBF/Target 21/Headline Indicators',
  KM_GBF_TARGET_21_COMPONENT = 'Policy/KM-GBF/Target 21/Component Indicators',
  KM_GBF_TARGET_21_COMPLIMENTARY = 'Policy/KM-GBF/Target 21/Complimentary Indicators',

  KM_GBF_TARGET_22 = 'Policy/KM-GBF/Target 22',
  KM_GBF_TARGET_22_HEADLINE = 'Policy/KM-GBF/Target 22/Headline Indicators',
  KM_GBF_TARGET_22_COMPONENT = 'Policy/KM-GBF/Target 22/Component Indicators',
  KM_GBF_TARGET_22_COMPLIMENTARY = 'Policy/KM-GBF/Target 22/Complimentary Indicators',

  KM_GBF_TARGET_23 = 'Policy/KM-GBF/Target 23',
  KM_GBF_TARGET_23_HEADLINE = 'Policy/KM-GBF/Target 23/Headline Indicators',
  KM_GBF_TARGET_23_COMPONENT = 'Policy/KM-GBF/Target 23/Component Indicators',
  KM_GBF_TARGET_23_COMPLIMENTARY = 'Policy/KM-GBF/Target 23/Complimentary Indicators',
}

export enum LayerType {
  raster = 'raster',
  vector = 'vector',
  geojson = 'geojson',
  group = 'group',
  video = 'video',
}

export enum LayerProvider {
  cartodb = 'cartodb',
  gee = 'gee',
  mapbox = 'mapbox',
  leaflet = 'leaflet',
}

export enum MapType {
  ELSA = 'ELSA',
  MLOD = 'MLOD',
}
