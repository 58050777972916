import { format } from 'd3-format';
import React from 'react';

import WidgetTooltip from '../../../../components/widget/tooltip';
import { IPlace } from '../../../../modules/places/model';
import { IWidgetConfig } from '../../../../modules/widget/model';
import { formatKM2, MONTHS, replace } from '../../utils';
import { FireMetric } from './model';

interface FireConfig {
  metric: FireMetric;
}

const MONTH_COLOURS = [
  '#ffffb2',
  '#ffed93',
  '#ffda73',
  '#fec759',
  '#feb04d',
  '#fe9942',
  '#fb7e37',
  '#f6602c',
  '#f24222',
  '#e22b21',
  '#d01523',
  '#bd0026',
];

export const CONFIG = {
  parse: ({ metric }: FireConfig, params, widgetConfig: IWidgetConfig, place: IPlace) => {
    if (!metric?.area_burned_km2) {
      return {
        chart: [],
        noData: true,
        template: '',
      };
    }
    const { sentence } = widgetConfig;

    const years = Object.keys(metric.area_burned_km2).map(Number);
    const minYear = Math.min(...years);
    const maxYear = Math.max(...years);

    const chart = [];
    for (const year in metric.area_burned_km2) {
      for (let month = 0; month < 12; month++) {
        chart.push({
          date: `${MONTHS[month]} ${year}`,
          areaBurnedKm2: metric.area_burned_km2[year][month] || 0,
          color: metric.area_burned_km2[year][month] ? MONTH_COLOURS[month] : null,
        });
      }
    }

    const yearlyTotalAreaBurned: { [year: string]: number } = Object.entries(
      metric.area_burned_km2
    ).reduce((acc, [year, yearData]) => {
      const yearSum = Object.values(yearData).reduce((sum: number, val: number) => sum + val, 0);
      acc[year] = yearSum;
      return acc;
    }, {});
    const { [maxYear]: maxYearTotalAreaBurned, ...prevYearsAreaBurned } = yearlyTotalAreaBurned;

    const sumPrevYearsAreaBurned = Object.values(prevYearsAreaBurned);
    const meanPrevYearsTotalAreaBurned =
      sumPrevYearsAreaBurned.reduce((acc, val) => acc + val, 0) / sumPrevYearsAreaBurned.length;

    return {
      chart,
      template: replace(
        sentence,
        {
          location: place.name,
          maxYear,
          minYear,
          yearBeforeMaxYear: maxYear - 1,
          total: `${formatKM2(maxYearTotalAreaBurned)} km²`,
          annualMean: `${formatKM2(meanPrevYearsTotalAreaBurned)} km²`,
        },
        {},
        {
          className: 'text-bold',
        }
      ),
      config: {
        type: 'bar',
        height: 300,
        margin: { top: 10, right: 4, left: 46, bottom: 0 },
        xKey: 'date',
        yKeys: {
          bars: [
            {
              key: 'areaBurnedKm2',
              dataKey: 'areaBurnedKm2',
              itemColor: true,
              isAnimationActive: false,
            },
          ],
        },
        xAxis: {
          dataKey: 'date',
          axisLine: {
            stroke: '#CCC',
            strokeWidth: 1,
          },
          angle: -45,
          interval: 11,
          tickFormatter: (date) => date.split(' ')[1],
          tick: {
            fontSize: '11px',
            fontWeight: 500,
            fill: '#FFF',
          },
          dy: 6,
          dx: -2,
        },
        yTitle: 'Area Burned (km²)',
        yAxis: {
          domain: [0, 'auto'],
          axisLine: {
            stroke: '#CCC',
            strokeWidth: 1,
          },
        },
        cartesianGrid: {
          vertical: true,
          horizontal: false,
          strokeDasharray: '2 2',
        },
        unitFormat: formatKM2,
        tooltip: {
          cursor: {
            opacity: 0.1,
            stroke: '#F75353',
            strokeWidth: 3,
          },
          content: ({ payload }) => {
            if (payload.length) {
              return (
                <WidgetTooltip
                  hideZeros={false}
                  payload={payload}
                  settings={[
                    {
                      label: `Date:`,
                      key: 'date',
                    },
                    {
                      label: `Area burned:`,
                      key: 'areaBurnedKm2',
                      suffix: ' km²',
                      format: format(',.1f'),
                      color: payload[0].payload.color,
                    },
                  ]}
                />
              );
            }
            return null;
          },
        },
      },
    };
  },
};

export default CONFIG;
