import { format } from 'd3-format';
import React from 'react';

import WidgetTooltip from '../../../../components/widget/tooltip';
import { IPlace } from '../../../../modules/places/model';
import { IWidgetConfig } from '../../../../modules/widget/model';
import { MONTHS, replace } from '../../utils';
import { ProtectedAreaMetric } from './model';

interface ProtectedAreaConfig {
  metric: ProtectedAreaMetric;
}

export const CONFIG = {
  parse: ({ metric }: ProtectedAreaConfig, params, widgetConfig: IWidgetConfig, place: IPlace) => {
    if (!metric?.land_area) {
      return {
        noData: true,
        chart: [],
        template: '',
      };
    }

    const {
      land_area,
      pa_land_area,
      percentage_pa_land_cover,

      marine_area,
      pa_marine_area,
      percentage_pa_marine_cover,

      date,
    } = metric;

    const land_outside_protected_area_perc = 100 - percentage_pa_land_cover;
    const land_outside_protected_area = land_area - pa_land_area;
    const land_data = {
      area_protected: pa_land_area,
      Protected: parseFloat(percentage_pa_land_cover.toFixed(2)),
      area_outside_protected: land_outside_protected_area,
      'Outside protected areas': parseFloat(land_outside_protected_area_perc.toFixed(2)),
    };

    const marine_outside_procted_perc = 100 - percentage_pa_marine_cover;
    const marine_outside_procted_area = marine_area - pa_marine_area;
    const marine_data = {
      area_protected: pa_marine_area,
      Protected: parseFloat(percentage_pa_marine_cover.toFixed(2)),
      area_outside_protected: marine_outside_procted_area,
      'Outside protected areas': parseFloat(marine_outside_procted_perc.toFixed(2)),
    };

    const { sentence, legendConfig } = widgetConfig;

    const protectedColor = legendConfig.items.find((i) => i.label === 'protected').color;
    const outsideProtectedColor = legendConfig.items.find((i) => i.label === 'other').color;

    const chart = [
      {
        type: 'Land',
        ...land_data,
      },
    ];
    if (marine_area) {
      chart.push({
        type: 'Marine',
        ...marine_data,
      });
    }

    const protectedAreaDate = new Date(date);
    const protectedAreaMonth = MONTHS[protectedAreaDate.getMonth()];
    const protectedAreaYear = protectedAreaDate.getFullYear();

    return {
      chart,

      template: replace(
        marine_area > 0 ? sentence.withMarine : sentence.default,
        {
          location: place.name,
          month: protectedAreaMonth,
          year: protectedAreaYear,
          percentage_protected_land: format('.2f')(percentage_pa_land_cover),
          percentage_protected_marine: format('.2f')(percentage_pa_marine_cover),
        },
        {},
        {
          className: 'text-bold',
        }
      ),
      config: {
        type: 'bar',
        layout: 'vertical',
        margin: { top: 0, right: 40, left: marine_area > 0 ? 40 : 10, bottom: 0 },
        height: marine_area > 0 ? 200 : 140,
        xKey: 'percentage',
        yAxis: {
          dataKey: 'type',
          type: 'category',
          ...(marine_area <= 0 && { tick: false }),
        },
        xAxis: {
          type: 'number',
          domain: [0, 100],
          ticks: [0, 30, 100],
          tick: {
            dy: -3,
            dx: 4,
            fontSize: '11px',
            fontWeight: 500,
            fill: '#FFF',
          },
          tickFormatter: (t) => `${t}%`,
        },
        yKeys: {
          bars: {
            Protected: {
              stackId: 'a',
              fill: protectedColor,
            },
            'Outside protected areas': {
              unit: '%',
              stackId: 'a',
              fill: outsideProtectedColor,
            },
          },
        },
        referenceLines: [
          {
            x: 30,
            stroke: '#32a852',
            strokeWidth: 2,
            strokeDasharray: '3 3',
          },
        ],
        cartesianGrid: {
          vertical: true,
          horizontal: false,
          strokeDasharray: '3 3',
        },
        legend: { iconType: 'square' },
        tooltip: {
          cursor: false,
          content: ({ payload, active }) => {
            if (active && payload && payload.length > 0) {
              const { type } = payload[0].payload;
              const settings: any[] = [
                {
                  key: 'type',
                },
              ];
              settings.push(
                {
                  label: 'Protected areas:',
                  key: 'area_protected',
                  format: (value) =>
                    `${format(',.0f')(value)} km² (${format(',.2f')(
                      chart.find((v) => v.type == type).Protected
                    )}%)`,
                  color: protectedColor,
                },
                {
                  label: 'Outside protected areas:',
                  key: 'area_outside_protected',
                  format: (value) =>
                    `${format(',.0f')(value)} km² (${format(',.2f')(
                      chart.find((v) => v.type == type)['Outside protected areas']
                    )}%)`,
                  color: outsideProtectedColor,
                }
              );

              return <WidgetTooltip hideZeros={false} payload={payload} settings={settings} />;
            }
            return null;
          },
        },
      },
    };
  },
};

export default CONFIG;
